/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    div: "div"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 400px; \"\n    >\n      <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 75.3012048192771%; position: relative; bottom: 0; left: 0; background-image: url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAIEBf/EABUBAQEAAAAAAAAAAAAAAAAAAAEA/9oADAMBAAIQAxAAAAF304phIRf/xAAaEAACAwEBAAAAAAAAAAAAAAACAwABBBIU/9oACAEBAAEFAmW3PfoB46GFR9VG50nCs+v/xAAWEQEBAQAAAAAAAAAAAAAAAAAAARH/2gAIAQMBAT8BZX//xAAVEQEBAAAAAAAAAAAAAAAAAAAAIf/aAAgBAgEBPwGI/8QAHRAAAgEEAwAAAAAAAAAAAAAAAQIAERIhURBhcf/aAAgBAQAGPwKoUMkwQrdwWuRjgmlp2Jv2f//EABsQAQADAAMBAAAAAAAAAAAAAAEAESExQVHw/9oACAEBAAE/ITvttaTE8Q6g4DXo2CZcFT6PEcm69ILqf//aAAwDAQACAAMAAAAQdy//xAAXEQEBAQEAAAAAAAAAAAAAAAABABFh/9oACAEDAQE/EFduF//EABYRAQEBAAAAAAAAAAAAAAAAAAEREP/aAAgBAgEBPxAYmP/EABwQAQACAgMBAAAAAAAAAAAAAAERIQAxQWGBcf/aAAgBAQABPxCqIVFzt4+6wFiONL2OnNJZjeu7ZvCGxJkjC6QrFHro5FhQTgKJ8M//2Q=='); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image\"\n        alt=\"led zeppelin\"\n        title=\"led zeppelin\"\n        src=\"/static/721dc02fff0d34e4f5b1aef41b2b6634/4cda9/led-zeppelin.jpg\"\n        srcset=\"/static/721dc02fff0d34e4f5b1aef41b2b6634/4d5fb/led-zeppelin.jpg 166w,\n/static/721dc02fff0d34e4f5b1aef41b2b6634/558f0/led-zeppelin.jpg 333w,\n/static/721dc02fff0d34e4f5b1aef41b2b6634/4cda9/led-zeppelin.jpg 400w\"\n        sizes=\"(max-width: 400px) 100vw, 400px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n    </span>"
    }
  })), "\n", React.createElement(_components.p, null, "Led Zeppelin Album Cover"), "\n", React.createElement(_components.p, null, "Forming in 1968 this band was comprised of the same four members for the 12 years that they produced music. Even after the death of their drummer, John Bonham, in 1980 they never replaced him and decided to dismantle the band instead. One of the most successful and influential rock bands in history they initially used the name “The New Yardbirds” however they received a letter from Chris Dreja of The Yardbirds asking them to drop the infringing name. Later, while in a conversation with 2 band members from The Who it was suggested that they all form a super group to include Keith Moon, John Entwistle, Jimmy Page and Jeff Beck. That super group it was thought, would go over like a “lead balloon”. It was decided that the name concept was a good replacement for The New Yardbirds and they adopted it after changing “lead” to “led” to avoid it being pronounced “leed” and used “zeppelin” in place of balloon. Led Zeppelin as we know it was therefore born. “Stairway to Heaven” from their 4th album, is often noted as being the most requested song to be played on the radio. They would later disband following the death of John Bonham in 1980 but not before being called “the heaviest band of all time” by Rolling Stone magazine. Interestingly, John Bonham’s son went on to be a professional drummer like his father and is most known for his contributions to the band Foreigner. "));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
